<template>
      <main id="main" class="site-main">
        <blogdetails />
    </main>
</template>
<script>
import Blogdetails from './Components/NLSE/Blogdetails.vue'
export default {
  name: 'MenuMV',
  components: {
    Blogdetails
  }
}
</script>
