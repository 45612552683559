<template>
    <div class="main-content">
        <!-- Blog Details Start-->
        <section class="section section-blog">
            <div class="container">
                <div class="row">
                    
                    <div class="col-lg-8 order-lg-1 mt-lg-0 mt-4 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1.5s">
                        <article class="blog-post-left border mb-5">
                            <div class="row">
                                <div class="col-lg-12">
									<div class="post-image">
                                            <img src="../../../../assets/images/NXT_level_invite_august_13_web.jpg" class="img-fluid img-thumbnail border-0" loading="lazy">
									</div>
                                    <div class="blog-post-content text-left mt-3">
                                        <h4 class="blog-post-title">
                                            <div class="text-left text-light">
                                                August 13th | 6 PM
                                            </div>
                                        </h4>
                                        <!-- <div class="post-event d-flex align-items-center my-3">
                                         <h4> <strong> <a href="">Menu</a> </strong> </h4>
                                        </div> -->
                                    </div>
									<!-- <div>
									<p> <i> First Course </i> </p>
									
									<div class="adjust-line-height">
									<a href=""><strong> Island Greens with Gorgonzola and Toasted Hazelnuts </strong></a> <br>
									Shaved red onions, balsamic vinaigrette
									</div>
									<br>

									<p> <i> Main Course (Choose one)</i> </p> 

									<div class="adjust-line-height">
									<a href=""> <strong> Red Wine Braised Short Rib with Truffle Whipped Potatoes </strong> </a> <br>
									Shaved asparagus, radish, red wine reduction <br>
									</div>

									<br>

									<div class="adjust-line-height">
									<a href=""> <strong> Grilled Atlantic Swordfish with Dill Whipped Potatoes </strong></a> <br>
									Watercress, preserved lemon, crispy capers, lemon beurre blanc   <br>
									</div>

									<br>

									<div class="adjust-line-height">
									*Vegetarian Option Available Upon Request 
									</div>

									<br>

									<p> <i> Desserts</i> </p> 
									
									<div class="adjust-line-height">
									<a href=""> <strong> Strawberry Shortcake </strong> </a> <br>
									Fresh cream, mint <br>
									</div>
									<br>

									</div> -->
                                    <div class="post-content">
 										<div class="post-event d-flex align-items-center my-3">
                                         <h4> <a href=""><strong> Sponsorship Opportunities </strong></a> </h4>
                                        </div>
										<p class="f-16">
											Next Level Sports & Entertainment is a global multi-sports and general entertainment content provider. Promoted as the sole black/minority-owned 24/7 linear platform distributed across digital and broadcast networks, Next Level features compelling and exciting sports and entertainment content developed for urban audiences. Next Level’s mission is to showcase the often untold or unrecognized stories and achievements that promote athletes, teams, sports, programs, and communities. 
										</p>
                                        <p class="f-16">
											Next Level Owner/CEO André Gudger envisioned creating a premier platform for programming specifically directed toward, and created by, the urban community. His strategy is to give back to the community in a variety of ways—exposure, quality content, and job creation. With André’s leadership and guidance, Next Level will continue to elevate sports, news, and entertainment coverage through original programming and customized experiences that speak to urban communities across the country and around the world. 
										</p>
										<p class="f-16">
											Today, Next Level reaches an audience of more than 16 million U.S. households and more than 61 million global households. Our network is currently available on Verizon, AT&T, Direct TV, and many more cable, digital, and streaming platforms. 
										</p>
										<p class="f-16">
											Next Level Sports & Entertainment is seeking your support to “change the game” for urban-focused sports and entertainment broadcasting. Our potential is only limited by our opportunity, and your assistance will help us take our efforts to the NEXT LEVEL. 
										</p>
										<p class="f-16">
											We encourage you to connect with us and explore the various sponsorship packages available. Whether it’s one or multiple events and/or one or multiple programs, we provide the opportunity to engage with our core audience to promote your products, services, or brand. All options are negotiable, and all support is welcome. 
										</p>
										<p class="f-16">
											Support the urban community and take your brand awareness to the NEXT LEVEL by partnering with us.
										</p>
										<div class="post-event d-flex align-items-center my-3">
                                         <h4> <a href=""><strong> Assets for Consideration </strong></a> </h4>
                                        </div>
										<p> Please review the available linear, digital, and in-person assets that your business can access. Frequency and placement will be based on your customized sponsorship package. We will work with you to tailor your message by pairing it with the content most desirable to your target audience. </p>
										
										<h5> <p style="padding: 10px; border: 2px solid gold;"><strong> On Air </strong></p> </h5>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:40%;"><strong>Commercials</strong></td>
											    <td style="width:60%;">1-minute "institutional" background </td> 
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">15-second "bookends"</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">15- or 30-second "Donuts"</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">6-second "Flash pan"</td>
											</tr>
										<tr>
												<td style="width:40%;"><strong>"In game" opportunities</strong></td>
											    <td style="width:60%;">Post/pre-game player and coach interviews</td> 
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Fan cam interactions</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Scoreboard/score card logo placement</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Field/court/venue logo placement</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Replays and highlights segments</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Announcer mentions</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Halftime show production</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">On-set product placement</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Top-of-the-segment copy lines</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Automated or live snipe pop-ups</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Digital overlays, either static or animated</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Logo bugs</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Time/weather updates</td>
											</tr>
										</table>
										<br>
										<h5> <p style="padding: 10px; border: 2px solid gold;"><strong> Digital </strong></p> </h5>
										<table  style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:40%;"></td>
											    <td style="width:60%;">Social media, all platforms </td> 
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Website ads</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Industry newsletter ads</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Curated content</td>
											</tr>
										</table>
										<br>
										<h5> <p style="padding: 10px; border: 2px solid gold;"><strong> In-person </strong></p> </h5>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:40%;"></td>
											    <td style="width:60%;">Co-hosted quarterly “NXT Level Experiences”</td> 
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Game guides/information booklets</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Celebrity endorsements</td>
											</tr>
											<tr>
												<td style="width:40%;"></td>
												<td style="width:60%;">Community impact engagement</td>
											</tr>
										</table>
										<br>
										<div class="post-event d-flex align-items-center my-3">
                                         <h4> <a href=""><strong> Sponsorship Levels</strong></a> </h4>
                                        </div>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:20%;"><h4><strong>$1,000,000+</strong></h4></td>
											    <td style="width:80%;"></td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">On-air network advertising and all in-game opportunities</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top-tier sponsorship of at least 3 championship games, tournaments, and classics</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Prime placement across all digital platforms</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top-tier billing at all “NXT Level Experience” events and other quarterly events</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Logo included on all physical collateral at all in-person events</td>
											</tr>

										</table>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:20%;"><h4><strong>$500,000+</strong></h4></td>
											    <td style="width:80%;"></td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">On-air network advertising and specific in-game opportunities</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top-tier sponsorship of 2 championship games, tournaments, and classics</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Advertising across all digital platforms</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top-tier billing at all “NXT Level Experience” events and other quarterly events</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Logo included on all physical collateral at all in-person events</td>
											</tr>
										</table>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:20%;"><h4><strong>$250,000+</strong></h4></td>
											    <td style="width:80%;"></td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Specific in-game advertising opportunities </td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top-tier sponsorship of 1 championship game, tournament, or classic</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Advertising across all digital platforms</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Advertising across all digital platforms</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top-tier billing at all “NXT Level Experience” events and other quarterly events</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Logo included on all physical collateral at specific in-person events</td>
											</tr>
										</table>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:20%;"><h4> <strong>$100,000+</strong> </h4></td>
											    <td style="width:80%;"></td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Logo bugs during on-air broadcasts</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Advertising across all social media platforms</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Top billing at all “NXT Level Experiences” events and other quarterly events</td>
											</tr>
											<tr>
												<td style="width:20%;"></td>
												<td style="width:80%;">Logo included on specific physical collateral at certain in-person events</td>
											</tr>
										</table>
										<br>
										<div class="post-event d-flex align-items-center my-3">
                                         <h4> <a href=""><strong> Event-Specific Sponsorship Opportunities</strong></a> </h4>
                                        </div>
										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:20%;"><h4> <strong>$50,000+</strong></h4></td>
											    <td style="width:80%;"></td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
											    <td style="width:80%;">Access to targeted network advertising opportunities </td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
											    <td style="width:80%;">Premium digital advertising </td> 
											</tr>
											<tr>
												<td style="width:20%;"></td>
											    <td style="width:80%;">Logo on all fan giveaways and physical collateral </td> 
											</tr>
										</table>
										<br>
										<br>
										<div>
										<h5><strong>The Goodman League Games</strong> (<i>through 2023</i>) </h5>
										<p><i>The premiere summer basketball league games broadcast from Southeast Washington DC and featuring some of the some of the top high school, street ball, overseas, and NBA talent in the nation.</i></p>
										<p>*Additional “in-game” opportunities will be available specially for The Goodman League playoffs </p>

										<h5><strong>Nation United </strong> (<i>starting late Summer 2021</i>) </h5>
										<p><i>A premier lacrosse league promoting inclusion and equity while showcasing and promoting some of the best players-of-color in the nation.</i></p>

										<h5><strong>The Crown Classic – Annual HBCU Lacrosse Tournament</strong> (<i>starting Fall 2021</i>) </h5>
										<p><i>Next Level’s proprietary event featuring 8 HBCU schools from the Mid-Atlantic region competing for “The Crown.” </i></p>
										
										<h5><strong>Athlete Documentaries </strong> (<i>starting Fall 2021</i>) </h5>
										<p><i>We chronicle the lives, experiences, and stories of some of the best athletes in the world. Our initial features showcase Olympians Tori Franklin and Donald Scott and NBA superstar Quinn Cook.</i></p>
										</div>

										<table style="table-layout: fixed; width: 100%;">
											<tr>
												<td style="width:20%;"><h4> <strong>$10,000+</strong></h4></td>
											    <td style="width:80%;"></td> 
											</tr>
										</table>
										<br>
										<div>
										<h5><strong>NXT Level Experience Events</strong> (<i>ongoing</i>) </h5>
										<p><i>Exclusive curated, invitation-only events highlighting the best in urban sports and entertainment. We launched this series in August 2021 with a premier event in Martha’s Vineyard.</i></p>					
										</div>

									</div>
									    <button id="submit" name="submit" type="submit" value="Send" class="btn btn-hover iq-button" style="display: block; margin: 0 auto;" >
										<a href="mailto:Michael.blake@nlse.com"><span class="iq-btn-text-holder" style="color:#FFFFFF">Contact US</span> </a>
										<br>
										</button>
                                </div>

                            </div>
                        </article>
                    </div>
					<div class="col-lg-4 order-lg-2 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1.5s">
                        <aside class="sidebar sidebar-padding border">
                            <div class="tags-post">
                                <ul class="blog-news list-unstyled">
                                    <li class="blog-item">
                                        <h6 class="post-news-title">Tags</h6>
                                    </li>
                                </ul>

                                <div class="blog-news-content">
                                    <div class="blog-post-list">
                                        <ul class="list-unstyled list-inline mb-0">
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">Sports </a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">Events </a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">NLSE</a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">Sponsorship</a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">Menu</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
							<div class="blog-item">
								<h6 class="post-news-title">Subscribe to NLSE</h6>
							</div>
							<form action="" @submit.prevent="sendEmail" class="wpcf7-form init" novalidate="novalidate" data-status="init">
								<input type="hidden" name="to_email" :value="contactFormEmailTo" size="40">
								<input type="hidden" name="cc_email" :value="contactFormEmailCc" size="40">
								<div class="col-md-12 cfield">
									<p v-if="errors.length">
										<b>Please correct the following error(s):</b>
										<ul>
											<li v-for="error in errors" :key="error">{{ error }}</li>
										</ul>
									</p>
								</div>
								<div class="contact-form contact-style">
									<div class="col-md-6 cfield">
										<input type="email" name="email" v-model="email" value="" size="100" aria-required="true" aria-invalid="false" placeholder="Your Email">
									</div>
								<div class="col-md-6 cfield">
									<button id="submit" name="submit" type="submit" value="Send" class="btn btn-hover iq-button">
										<span class="iq-btn-text-holder">Subscribe</span>
										<br>
										</button>
								</div>
								</div>
							</form>
                        </aside>
						<br>
						<div class="post-image">
											<h3> <a href="" style="color:#FFD700"> <strong> Event Sponsors </strong></a> </h3>
                                            <img src="../../../../assets/images/NXT_level_invite_august_13_sponsors.jpg" class="img-fluid img-thumbnail border-0" loading="lazy">
						</div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Blog Details Section End-->
    </div>
</template>
<script>
import emailjs from 'emailjs-com'

export default {
  name: 'blogdetails',
  data () {
    return {
      email: '',
      message: '',
      errors: [],
      contactFormEmailTo: 'nilanjan.banerjee@eccalon.com',
      contactFormEmailCc: 'nilanjan.banerjee@eccalon.com'
    }
  },
  methods: {
    sendEmail (e) {
      this.errors = []
      if (!this.email) this.errors.push('Email required.')
      if (this.email) {
        try {
          emailjs.sendForm('service_914fhnu', 'template_q0tvcye', e.target,
            'user_C6JHRFE95hQ3x7jJ8aOl1')
        } catch (error) {
          console.log({ error })
        }
        // Reset form field
        this.email = ''
      }
    }
  }
}
</script>
<style scoped>

td {
  width: 5% ;
}
.adjust-line-height {
  line-height: 1em;
}
br {
        line-height: 50%;
     }
.blog-news-content .blog-post-info a {
    font-size: 16px;
}

.blog-news-content li:hover .blog-post-info a {
    color: #1C4CA3 !important;
}

.blog-news-content .blog-post-info .post-date {
    font-size: 14px;
    line-height: normal;
    margin-top: 5px;
}

.post-news-title {
    font-size: 18px;
    margin-bottom: 20px;
    padding-bottom: 11px;
    position: relative;
    border-bottom: 1px solid #eee;
}

.post-event a {
    color: #f2b636;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

    .post-event a:hover {
        color: #f2b636;
    }

.post-news-title:before {
    position: absolute;
    width: 50px;
    height: 2px;
    content: "";
    left: 0;
    bottom: -1px;
    opacity: 1;
    background-color: #f2b636;
}

.sidebar-padding {
    padding: 30px;
}

.blog-post-left {
    padding: 10px;
}

.blog-side-padding {
    margin-top: 30px;
}

.sidebar, .blog-post-left, .blog-author-section {
    border-color: #eee !important;
    position: relative;
}

    .blog-post-left::before {
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;
        content: "";
        width: 0;
        height: 3px;
        position: absolute;
        left: 50%;
        bottom: 0;
        opacity: 0;
        background-color: #f2b636;
    }

    .blog-post-left:hover::before {
        width: 100%;
        opacity: 1;
        left: 0;
    }

    .sidebar input.form-control {
        font-size: 15px;
        height: auto;
        border: none;
        border: 2px solid #ededed;
        border-radius: 25px !important;
        background-color: transparent;
        color: #000;
        padding-left: 12px;
        padding-right: 60px;
    }

        .sidebar input.form-control:focus {
            box-shadow: none;
            outline: none;
        }

        .sidebar input.form-control::placeholder {
            color: #989898;
            font-weight: 400;
        }

.btn-search {
    line-height: normal;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 25px 25px 0;
    height: 40px;
    width: 50px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

    .btn-search:hover {
        background-color: #f2b636;
        ;
    }

    .btn-search .mdi {
        color: #fff;
        font-size: 24px;
    }

.blog-post-list li {
    margin: 0 0 15px 0;
}

.blog-post-list li {
    border-bottom: none;
    padding-bottom: 0;
}

.blog-news-content .blog-post-info a {
    font-size: 16px;
}

.blog-news-content li:hover .blog-post-info a {
    color: #1C4CA3 !important;
}

.blog-news-content .blog-post-info .post-date {
    font-size: 14px;
    line-height: normal;
    margin-top: 5px;
}

.author-img {
    display: contents;
}

.tags-post li a {
    position: relative;
    display: inline-block;
    padding: 6px 15px;
    font-size: 15px !important;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid #ededed;
    transition: all 0.5s ease-in-out;
}

    .tags-post li a:hover {
        background-color: #f2b636;
        color: #fff !important;
        border: 1px solid #f2b636;
    }

.blockquote {
    border-left: 2px solid #ededed;
    padding: 10px 0 10px 40px;
}

    .blockquote p {
        font-size: 18px;
    }

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

/*==========================
        8.PORTFOLIO
============================*/
.portfolio-box {
    overflow: hidden;
    position: relative;
}

    .portfolio-box:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: -webkit-linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 44.79%,rgba(0,0,0,.35) 63.54%,rgba(0,0,0,.80) 100%);
        background: -o-linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 44.79%,rgba(0,0,0,.35) 63.54%,rgba(0,0,0,.80) 100%);
        background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 44.79%,rgba(0,0,0,.35) 63.54%,rgba(0,0,0,.80) 100%);
        transition: all .4s ease;
    }

    .portfolio-box img {
        transition: all 0.4s;
    }

.filters li a {
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    padding: 5px 20px 5px;
    color: #314584;
    border-radius: 30px;
    font-weight: 400;
    border: 2px solid #314584;
    transition: all 0.3s;
    margin-bottom: 10px;
}

    .filters li a.active, .filters li a:hover {
        background: #f2b636;
        color: #fff;
        border: 2px solid #f2b636;
    }

.img-overlay {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 1rem 2rem;
    transition: all .3s ease;
    z-index: 9;
}

.portfolio-box h5 {
    font-size: 18px;
}

.portfolio-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    visibility: hidden;
    opacity: 0;
    font-size: 24px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 2;
}

    .portfolio-icon a {
        color: #fff;
    }

        .portfolio-icon a:hover {
            color: #fff;
        }

    .portfolio-icon:hover {
        background: #f2b636;
    }

.portfolio-box:hover .portfolio-icon {
    visibility: visible;
    opacity: 1;
}

.portfolio-box:hover:after {
    background: linear-gradient(180deg,rgba(0,0,0,.80) 0%,rgba(0,0,0,.80) 44.79%,rgba(0,0,0,.80) 63.54%,rgba(0,0,0,.80) 100%);
}
.isotope, .isotope .isotope-item {
    transition: 0.8s;
}

    .isotope .isotope-item {
        -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property: -moz-transform, opacity;
        transition-property: transform, opacity;
    }

.scale-image {
    overflow: hidden;
}
</style>
